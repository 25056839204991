<template>
  <div class="jsonEditor-container">
    <!-- 输入框 -->
    <div class="components-input">
      <div class="tinymceSchedule_title">
        <img src="../../../assets/bgm/08.png" alt="" />
        <p>账户交易记录</p>
      </div>
      <el-row :gutter="20" style="margin-top: 30px">
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="发薪企业：">
                <el-select
                  v-model="form.humanEntId"
                  size="medium"
                  placeholder="全部"
                  style="width: 100%"
                  @change="searchState"
                  filterable
                >
                  <el-option
                    v-for="(item,index) in option"
                    :key="index"
                    :label="item.entName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="用工企业：">
                <el-select
                  v-model="form.entId"
                  size="medium"
                  placeholder="全部"
                  style="width: 100%"
                  @change="searchStates"
                  filterable
                >
                  <el-option
                    v-for="(item,index) in employmentOption"
                    :key="index"
                    :label="item.entName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <el-form ref="form" :model="form" label-width="100px">
              <el-form-item label="交易时间：">
                <el-date-picker
              v-model="tiem"
              type="daterange"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDatePayTime"
              unlink-panels
              style="width: 95%"
              size="medium"
              :clearable="false"
              :picker-options="pickerOption"
              :default-value="new Date()"
            >
            </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple" style="width: 300px;">
            <el-button type="primary" @click="inquire" size="medium" style="background: #6591f1">
              <i class="el-icon-search" style="margin-right: 7px"></i>查询</el-button>
            <el-button plain size="medium" @click="reset" style="background: #f7f8fa">
              <i class="el-icon-refresh-left" style="margin-right: 7px"></i>重置</el-button>
            <el-button type="primary" @click="educeDerice" size="medium" style="background: #6591f1"
            v-if="$store.state['user']['PermissionCodeList'].includes('exportRecord')">
              <i class="el-icon-edit-outline" style="margin-right: 7px"></i>导 出</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="components-form">
      <el-table
        :data="pageList.list"
        style="width: 100%"
        v-loading="loadingTable"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        :header-cell-style="{ background: '#6591F1', color: '#fff' }"
        size="small"
        max-height="800"
      >
        <el-table-column type="index" label="序号" width="80"></el-table-column>
        <el-table-column
          prop="subAccoutName"
          label="用工企业"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="交易通道"
          align="center"
          show-overflow-tooltip
        >
        <template>
          <span>{{'平安银行'}}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="subAccount"
          label="账户账号"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="oppAccountNo"
          label="交易方账号"
          align="center"
          show-overflow-tooltip
          min-width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="bizFlowNo"
          label="交易流水号"
          align="center"
          show-overflow-tooltip
          min-width="120px"
        >
        </el-table-column>
        <el-table-column
          prop="tranDate"
          label="交易时间"
          align="center"
          show-overflow-tooltip
          width="180px"
        >
        <template slot-scope="{ row }">
          <span v-if="row.tranDate !== null">
            <span>{{ row.tranDate }} {{ row.tranTime }}</span>
          </span>
          <span v-else>-</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="dcFlag"
          label="交易方式"
          align="center"
          show-overflow-tooltip
        >
        <template slot-scope="{ row }">
            <div v-if="row.dcFlag === null">-</div>
            <div>
              <el-tag type="info" style="color: #6591f1" v-if="row.dcFlag == 'D'">支出</el-tag>
              <el-tag
                type="warning"
                style="color: #E84B4D "
                v-else-if="row.dcFlag == 'C'"
                >收入</el-tag
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="tranAmount"
          label="交易金额"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          align="center"
          show-overflow-tooltip
        >
        <template slot-scope="{ row }">
          <span v-if="row.remark !== null">
            <span>{{ row.remark }}</span>
          </span>
          <span v-else>-</span>
        </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="pagination">
        <p>共{{this.pageList.total}} 条记录 第 {{this.pageList.pageNum}} 页</p>
        <el-pagination
          background
          @size-change="currentChange"
          @current-change="changePage"
          :page-size="form.pageSize"
          :current-page="form.pageIndex"
          :page-sizes="[100, 200, 300, 500]"
          layout="total,prev, pager, next, sizes,jumper"
          :total="pageList.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { instance } from "@/utils/request";
export default {
  name: 'jsonEditor-demo',
  data() {
    return {
      loadingTable: false,
      form: {
        pageIndex: 1,
        pageSize: 100,
        entId: null,
        humanEntId: "",
        startTime: "",
        endTime: "",
        opFlag: 1,
      },
      tiem:'',
      pageList:"",
      trxdat1Well: "",
      trxdat2Well: "",
      trxdat3Well: "",
      trxtim1Well: "",
      trxtim2Well: "",
      trxtim3Well: "",
      option:[],
      employmentOption:[],
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > (Date.now());
        },
      },
    }
  },
  created(){
    this.startingTimeWell()
    this.endTimeWell()
  },
  mounted(){
    this.payrollEnterprise()
    this.employmentEnterprises()
  },
  methods: {
    //点击导出
    educeDerice() {
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.toExportExcel();
      }, 500);
    },
    toExportExcel() {
      const { form } = this;
      const { pageIndex, pageSize,entId,humanEntId,startTime,endTime,opFlag } = form;
      instance({
        method: "POST",
        url: "/v1/pAcctSon/recRecordPageListWrite",
        data: {
          pageIndex,
          pageSize,
          entId,
          humanEntId,
          startTime,
          endTime,
          opFlag
        },
        responseType: "blob",
      }).then((res) => {

        const link = document.createElement("a"); //创建a标签
        let blob = new Blob([res], { type: "application/vnd.ms-excel" }); // response就是接口返回的文件流
        let objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = "账户交易记录列表"; // 自定义文件名
        link.click(); // 下载文件
        URL.revokeObjectURL(objectUrl); // 释放内存

      });
    },
    payrollEnterprise() {
      instance({
        method: "post",
        url: "/v1/sysEnt/payConfigEnterprises",
      })
        .then((res) => {
          if (res.success) {
            this.option = res.data
            this.form.humanEntId = this.option[0].id
            this.getData()
          }
        })
        .catch((err) => {});
    },
    employmentEnterprises() {
      instance({
        method: "post",
        url: "/v1/sysEnt/getEntList",
      })
        .then((res) => {
          if (res.success) {
            this.employmentOption = res.data
            this.employmentOption.unshift({ id: null, entName: "全部" });
          }
        })
        .catch((err) => {});
    },
     // 选择日期
     changeDatePayTime() {
      for (let i = 0; i < this.tiem.length; i++) {
        this.form.startTime = this.tiem[0].replace(/[-]/g,"");;
        this.form.endTime = this.tiem[1].replace(/[-]/g,"");
      }
      this.form.startTime = this.form.startTime + '000000'
      this.form.endTime = this.form.endTime + '000000'
    },
    startingTimeWell(){
      let times = new Date().getMonth();
      if(new Date().getMonth() + 1 < 10){
        times = 0 + "" + (new Date().getMonth()+1)
      }else if(new Date().getDate() == 1 && new Date().getMonth() < 10){
        times = 0 + "" + (new Date().getMonth())
      }else if(new Date().getMonth() + 1 >= 10){
        times = new Date().getMonth()+1
      } else{
        times = new Date().getMonth()
      }

      this.form.startTime = new Date().getFullYear() + "" + times + '01000000'
      sessionStorage.setItem("beginWell",this.form.startTime);
      console.log(this.form.startTime);
    },
    // 初始结束时间
    endTimeWell(){
      let ends = new Date().getMonth();
      let endss = new Date().getDate();
      if(new Date().getMonth() + 1 < 10 ){
        ends = 0 + "" + (new Date().getMonth()+1)
      }else if(new Date().getMonth() + 1 >= 10){
        ends = new Date().getMonth()+1
      }else if(new Date().getDate() == 1 && new Date().getMonth() < 10){
        ends = 0 + "" + (new Date().getMonth())
      }else if(new Date().getDate() != 1 || new Date().getMonth() >= 10){
        ends = new Date().getMonth()
      }
      if(new Date().getDate() < 10){
        endss = 0 + "" + (new Date().getDate())
      }else if(new Date().getDate() >= 10){
        endss = new Date().getDate()
      }else if(new Date().getDate() == 1 && new Date().getMonth() < 10){
        ends = 0 + "" + (new Date().getMonth())
      }else if(new Date().getDate() != 1 || new Date().getMonth() >= 10){
        ends = new Date().getMonth()
      }
      this.form.endTime = new Date().getFullYear() + "" + ends +  "" + endss + "000000"
      sessionStorage.setItem("finishWell",this.form.endTime)
      console.log(this.form.endTime);
    },
     // 筛选方法
    // 状态
    searchState(val) {
      this.form.humanEntId = val;
      this.getData()
    },
    searchStates(val){
      this.form.entId = val;
      if(this.form.entId != null){
        this.form.opFlag = 2
      }else{
        this.form.opFlag = 1
      }
      this.getData()
    },
    getData() {
      this.loadingTable = true;
      setTimeout(() => {
        this.loadingTable = false;
      }, 60000);
      const { form } = this;
      const { pageIndex, pageSize,entId,humanEntId,startTime,endTime,opFlag } = form;
      instance({
        method: "post",
        url: "/v1/pAcctSon/getTransactionList",
        data: {
          pageIndex,
          pageSize,
          entId,
          humanEntId,
          startTime,
          endTime,
          opFlag
        },
      })
        .then((res) => {
          if (res.success) {
            this.loadingTable = false;
            this.pageList = res.data;
 
          for (const item of this.pageList.list) {
            this.trxdat1Well = [...item.tranDate].splice(0,4);
            this.trxdat1Well = this.trxdat1Well.join("")
            this.trxdat2Well = [...item.tranDate].splice(4,2);
            this.trxdat2Well = this.trxdat2Well.join("")
            this.trxdat3Well = [...item.tranDate].splice(6,2);
            this.trxdat3Well = this.trxdat3Well.join("")
            item.tranDate = this.trxdat1Well + "-" + this.trxdat2Well + "-" + this.trxdat3Well

            this.trxtim1Well = [...item.tranTime].splice(0,2);
            this.trxtim1Well = this.trxtim1Well.join("")
            this.trxtim2Well = [...item.tranTime].splice(2,2);
            this.trxtim2Well = this.trxtim2Well.join("")
            this.trxtim3Well = [...item.tranTime].splice(4,2);
            this.trxtim3Well = this.trxtim3Well.join("")
            item.tranTime = this.trxtim1Well + ":" + this.trxtim2Well + ":" + this.trxtim3Well
          }
          
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
     // 查询
     inquire() {
      this.form.pageIndex = 1;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 重置页面
    reset() {
      this.form.entId = null;
      this.form.humanEntId = this.option[0].id;
      this.form.opFlag = 1
      this.form.startTime = sessionStorage.getItem("beginWell");
      this.form.endTime = sessionStorage.getItem("finishWell");
      this.form.pageIndex = 1;
      this.form.pageSize = 15;
      this.tiem = '';
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // 翻页
    changePage(val) {
      this.form.pageIndex = val;
      if (this.timers) {
        clearTimeout(this.timers);
      }
      this.timers = setTimeout(() => {
        this.getData();
      }, 500);
    },
    // f分页
    currentChange(val) {
      this.form.pageSize = val;
      this.form.pageIndex = 1;
      this.getData();
    },
  }
}
</script>

<style lang="less" scoped>
.jsonEditor-container {
  padding: 20px;
  .components-input {
    width: 100%;
    height: 169px;
    background: #ffffff;
    padding: 20px;
    .tinymceSchedule_title {
      width: 100%;
      border-bottom: 1px solid #ececec;
      display: flex;
      height: 40px;
      img {
        width: 22px;
        height: 22px;
        // margin-top: 14px;
        margin-right: 10px;
        // line-height: 22px;
      }
      p:first-child {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 22px;
        margin-left: 10px;
      }
    }
    // padding: 20px;
  }
  .components-form {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
    /deep/ .el-table {
      thead {
        height: 50px;
        background: #6591f1;
        font-weight: 600;
      }
      .el-table__cell.is-center {
        text-align: left;
      }
      .cell {
        padding-left: 30px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  /deep/ .el-input__inner {
    background: #f7f8fa;
    border: none;
  }
  /deep/ .el-range-input {
    background: #f7f8fa;
  }
}
</style>